import { useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row } from 'antd';

import { FloorPlanViewer } from './FloorPlanViewer/FloorPlanViewer';
import { SidePanel } from './SidePanel/SidePanel';
import { useAppDispatch } from '@store/store';
import { getPunchListCategories, getPuchListItems, getPunchListPage, reset, getPuchListStatuses } from '@store/slices/punch-list';
import { isArrayWithValues } from '@shared/util/array-util';
import { isNumber } from '@shared/util/number-util';
import { getUsers } from '@store/slices/users-extended';
import useEventListener from '@HOOKs/UseEventListener';
import { If } from '@components/Utils/Structural';
import { useLayoutContex } from '@providers/LayoutProvider';
import './PunchListTab.less';
import { ExportSvgIcon } from '@components/Icons/ExportSvgIcon';
import { punchListService } from '@services/punch-list.service';
import { LoadingOutlined } from '@ant-design/icons';
import { openBinaryFile } from '@shared/util/download-util';
import { asyncLaunchNotification } from '@store/slices/notification';

export const PunchListTab = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [isInitialRequest, setIsInitialRequest] = useState(true);

  const { projectId } = useParams<'projectId'>();
  const { pageId } = useParams<'pageId'>();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getPunchListCategories());
    dispatch(getPuchListStatuses());

    return () => {
      dispatch(reset());
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (isInitialRequest) {
      setIsInitialRequest(false);
      if (projectId) {
        dispatch(getPunchListPage({ projectId }))
          .unwrap()
          .then(pages => {
            if (isArrayWithValues(pages)) {
              const selected = pageId ? pageId : pages[0].id;
              isNumber(selected) && dispatch(getPuchListItems({ pageId: selected! }));
            }
          });
      }
    }
  }, []);

  const { height: sidePanelHeight = 0, ref: sidePanelRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 });

  const reference = useRef<HTMLDivElement>(null);

  const [maxHeightContainer, setMaxHeightContainer] = useState(0);

  useEffect(() => {
    handleSize();
  }, [sidePanelHeight]);

  const handleSize = () => {
    const topPosition = (reference?.current && reference?.current?.getBoundingClientRect?.().top) || 0;
    if (isNumber(topPosition)) {
      setMaxHeightContainer(window.innerHeight - topPosition);
    }
  };

  useEventListener('resize', handleSize);

  const { collapsePunchList } = useLayoutContex();

  const [hovered, setHovered] = useState(false);

  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const onGeneratePdf = async () => {
    setLoadingPdf(true);
    punchListService
      .exportToPdf(projectId!)
      .then(({ blob, fileName }) => {
        openBinaryFile(blob, fileName, true);
      })
      .catch(e => {
        console.error('Export failed due to: ' + e);
        dispatch(
          asyncLaunchNotification({
            type: 'error',
            config: {
              message: t('punchList.exportPdfErrorTitle'),
              description: t('punchList.exportPdfErrorMessage'),
            },
          })
        );
      })
      .finally(() => setLoadingPdf(false));
  };

  return (
    <Row className="h-full" ref={reference}>
      <Col
        ref={sidePanelRef}
        span={`${collapsePunchList ? 1 : 6}`}
        className="overflow-hidden"
        style={{ maxHeight: maxHeightContainer, height: maxHeightContainer }}
      >
        <SidePanel />
      </Col>
      <Col span={`${collapsePunchList ? 23 : 18}`} className="overflow-hidden">
        <If condition={maxHeightContainer > 0}>
          <div style={{ position: 'relative' }}>
            <Button
              type="default"
              className="floating-button flex flex-wrap justify-start items-center"
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={onGeneratePdf}
            >
              <If condition={loadingPdf}>
                <LoadingOutlined className="pr-5" />
              </If>
              <If condition={!loadingPdf}>
                <ExportSvgIcon className="pr-5" hovered={hovered} />
              </If>
              {t('generic.export')}
            </Button>
            <FloorPlanViewer height={maxHeightContainer} />
          </div>
        </If>
      </Col>
    </Row>
  );
};
