import { geekblue } from '@ant-design/colors';
import { PunchListItemNormalizedStatusEnum } from '@models/enumerations/punch-list-item-normalized-status.enum';

export const getPunchStatusColor = (status: PunchListItemNormalizedStatusEnum) => {
  const getStatusColor = (status: string) => {
    const statusColorMap = {
      CLOSED: geekblue[6], // blue
      IN_PROGRESS: '#ffe58f', // yellow
      NEW: '#22BC72', // green
      OVERDUE: '#E43711', // red
    };
    return statusColorMap.hasOwnProperty(status) ? statusColorMap[status as keyof typeof statusColorMap] : undefined;
  };

  return getStatusColor(status);
};
