import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider, Form, GetRef, Input, InputRef, Table } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { SetSquareSvgIcon } from '@components/Icons/SetSquareSvgIcon';
import { useAppDispatch, useAppSelector } from '@store/store';
import { updateSubTotalCosts, updateLineItemCostRemote, updateLineItemCost } from '@store/slices/budget-extended';
import { getBudgetSummary } from '@store/slices/budget-summary';
import { getBudgetApprovers } from '@store/slices/budget-approvers';
import { budgetExtendedService } from '@services/budget-extended.service';
import { BudgetStatusEnum } from '@models/budget.model';
import { BudgetMapper } from '@mappers/Budget.mapper';
import { denormalizeNumberString, isValidNumberString } from '@shared/util/number-util';
import { BudgetDetailHeader } from './BudgetDetailHeader';
import { useTableHeight } from './UseTableHeight';
import '@styles/components/budget-detail-table.less';
import { openBinaryFile } from '@shared/util/download-util';
import { useThemeProvider } from '@providers/ThemeProvider';

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = createContext<FormInstance<any> | null>(null);

interface IBudgetLevel {
  id: number;
  key: React.Key;
  name: string;
  lineItemId?: StringORNumber;
  cost: string;
  costPerSF: string;
  children?: IBudgetLevel[];
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof IBudgetLevel;
  record: IBudgetLevel;
  handleSave: (record: IBudgetLevel) => void;
  tabIndex?: number;
  currencySymbol?: string;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  tabIndex = 1000,
  currencySymbol = '$',
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const onInputChange = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(save, 10000);
  };

  let childNode = children;

  if (editable && !Object.hasOwn(record, 'children')) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
          {
            validator(_, value) {
              return new Promise<void>((resolve, reject) => {
                if (!isValidNumberString(value)) reject('Invalid cost value');
                resolve();
              });
            },
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          //prefix={currencySymbol}
          onChange={onInputChange}
          onFocus={e => e.target.select()}
          tabIndex={tabIndex}
          onClick={toggleEdit}
          style={{ textAlign: 'right' }} //this does not work with prefix option.
        />
      </Form.Item>
    ) : (
      <div onClick={toggleEdit} onFocus={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export const BudgetDetailTable = () => {
  const [tableHeight] = useTableHeight();
  const { entity: budget, loading } = useAppSelector(state => state.BudgetExtended);
  const isEditMode = budget.status === BudgetStatusEnum.DRAFT;
  const dispatch = useAppDispatch();
  const dataSource = useMemo(() => (budget?.groups ? BudgetMapper.toUI(budget).groups : []), [budget]);
  const { t } = useTranslation();

  const { themeConfig } = useThemeProvider();

  const currencySymbol = budget?.currency?.symbol ?? '$';

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
  const allKeys = useMemo(() => {
    const keys: any[] = [];
    const getKeys = (data: any) => {
      data.forEach((item: any) => {
        keys.push(item.key);
        if (item.children) {
          getKeys(item.children);
        }
      });
    };
    getKeys(dataSource);
    return keys;
  }, [dataSource]);

  const [tabIndexArray, setTabIndexArray] = useState<{ key: string; tabIndex: number }[]>([]);
  const tabIndex = useMemo(() => {
    const indexArray: { key: string; tabIndex: number }[] = [];
    let currentIndex = 100;

    const buildIndex = (data: any) => {
      data.forEach((item: any) => {
        indexArray.push({ key: item.key, tabIndex: currentIndex });
        currentIndex++;
        if (item.children) {
          buildIndex(item.children);
        }
      });
    };

    buildIndex(dataSource);
    return indexArray;
  }, [dataSource]);

  useEffect(() => {
    setExpandedRowKeys(allKeys);
    setTabIndexArray(tabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTabIndexById = key => {
    const item = tabIndexArray.find(item => item.key === key);
    return item ? item.tabIndex : 1; // Return the tabIndex if found, otherwise return null
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: t('budget.categoryName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('budget.totalEstimatedCost'),
      dataIndex: 'cost',
      key: 'cost',
      width: '20%',
      editable: isEditMode,
      render: (value, record) => (
        <div
          className={`cost-cell-wrap ${isEditMode && !Object.hasOwn(record, 'children') ? 'editable' : ''}`}
          tabIndex={isEditMode && !Object.hasOwn(record, 'children') ? getTabIndexById(record.key) : undefined}
        >
          <span className="cost-cell-currency">{currencySymbol}</span>
          <span>{value}</span>
        </div>
      ),
    },
    {
      title: () => (
        <div className="flex justify-between items-center">
          <span className="cost-per-sf-cell-currency">{t('budget.costPerSF')}</span>
          <SetSquareSvgIcon />
        </div>
      ),
      dataIndex: 'costPerSF',
      key: 'costPerSF',
      width: '20%',
      render: value => (
        <div className="cost-per-sf-cell-wrap flex justify-end items-center">
          <span className="cost-per-sf-cell-currency">{currencySymbol}</span>
          <span>{value}</span>
        </div>
      ),
    },
  ];

  const handleSave = (row: IBudgetLevel) => {
    if (!row.lineItemId) return;
    dispatch(updateLineItemCost({ subCategoryId: row.id, cost: row.cost }));
    dispatch(updateLineItemCostRemote({ id: row.lineItemId, cost: denormalizeNumberString(row.cost) }))
      .unwrap()
      .then(res => {
        dispatch(getBudgetSummary(budget.id!));
        dispatch(updateSubTotalCosts({ subCategoryId: row.id, ...res }));
        dispatch(getBudgetApprovers(budget.id!));
      })
      .catch(errInfo => console.log('Save failed:', errInfo));
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IBudgetLevel) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        tabIndex: getTabIndexById(record.key),
        currencySymbol: currencySymbol,
        handleSave,
      }),
    };
  });

  const onExpandAll = () => setExpandedRowKeys(allKeys);
  const onCollapseAll = () => setExpandedRowKeys([]);
  const onExpand = (expanded: boolean, record: any) => {
    if (expanded) return setExpandedRowKeys(prev => [...prev, record.key]);
    return setExpandedRowKeys(prev => prev.filter(key => key !== record.key));
  };
  const expandIcon = ({ expanded, onExpand, record }) => {
    if (!record.children || record.children.length < 1) return <DownOutlined className="custom-expand-icon custom-expand-icon-disabled" />;
    return expanded ? (
      <DownOutlined className="custom-expand-icon" onClick={e => onExpand(record, e)} />
    ) : (
      <RightOutlined className="custom-expand-icon" onClick={e => onExpand(record, e)} />
    );
  };

  const [loadingExcel, setLoadingExcel] = useState<boolean>(false);
  const onGenerateExcel = async () => {
    setLoadingExcel(true);
    budgetExtendedService.exportToExcel(budget.id!).then(({ blob, fileName }) => {
      setLoadingExcel(false);
      openBinaryFile(blob, fileName, false);
    });
  };

  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const onGeneratePdf = async () => {
    setLoadingPdf(true);
    budgetExtendedService.exportToPdf(budget.id!).then(({ blob, fileName }) => {
      setLoadingPdf(false);
      openBinaryFile(blob, fileName, true);
    });
  };

  return (
    <>
      <BudgetDetailHeader
        onExpandAll={onExpandAll}
        onCollapseAll={onCollapseAll}
        onGenerateExcel={onGenerateExcel}
        loadingExcel={loadingExcel}
        onGeneratePdf={onGeneratePdf}
        loadingPdf={loadingPdf}
      />
      <ConfigProvider theme={{ token: { borderRadius: 8 } }}>
        <Table
          className="border-radius-8-custom-table"
          loading={loading}
          components={components}
          columns={columns as ColumnTypes}
          dataSource={dataSource}
          pagination={false}
          rowClassName={record => {
            if (record.key.includes('costGrouping-')) {
              return themeConfig.darkMode ? 'budget-group-row-dark' : 'budget-group-row';
            } else if (record.key.includes('costCategory-')) {
              return themeConfig.darkMode ? 'budget-category-row-dark' : 'budget-category-row';
            } else {
              return 'editable-row';
            }
          }}
          expandable={{
            expandedRowKeys,
            onExpand,
            expandIcon,
          }}
          size="small"
          sticky={true}
          scroll={{ y: tableHeight }}
          indentSize={20}
        />
      </ConfigProvider>
    </>
  );
};
