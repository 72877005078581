import { SVGProps } from 'react';

import { NEUTRAL_8_COLOR } from '@providers/ThemeProvider';

type IProps = SVGProps<SVGSVGElement>;

interface IExportProps extends IProps {
  hovered?: boolean;
}

export const ExportSvgIcon = (props: IExportProps) => {
  const { hovered, ...restProps } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M20.638 18.5704H22.079C22.1912 18.5704 22.2847 18.6613 22.2847 18.7761V21.46C22.2847 21.9151 21.918 22.2818 21.463 22.2818H2.53619C2.08114 22.2818 1.71443 21.9151 1.71443 21.46V2.53326C1.71443 2.07821 2.08114 1.7115 2.53619 1.7115H21.4657C21.9207 1.7115 22.2874 2.07821 22.2874 2.53326V5.21719C22.2874 5.33205 22.1939 5.42288 22.0817 5.42288H20.6407C20.5285 5.42288 20.435 5.32938 20.435 5.21719V3.56183H20.4344V3.56127H3.56476V3.56183H3.5642V20.4315H3.56476V20.432H20.4317V20.4315H20.4323V18.7761C20.4323 18.6639 20.5258 18.5704 20.638 18.5704ZM18.776 8.82834L22.5769 11.8283C22.6025 11.8483 22.6231 11.8739 22.6373 11.903C22.6515 11.9322 22.6589 11.9642 22.6589 11.9967C22.6589 12.0291 22.6515 12.0611 22.6373 12.0903C22.6231 12.1194 22.6025 12.145 22.5769 12.165L18.776 15.165C18.6371 15.2772 18.4287 15.1757 18.4287 14.9967V12.9609H18.4282V12.9604H10.0174C9.89989 12.9604 9.80371 12.8642 9.80371 12.7467V11.2467C9.80371 11.1291 9.89989 11.0329 10.0174 11.0329H18.4282V11.0324H18.4287V8.99665C18.4287 8.81765 18.6344 8.71613 18.776 8.82834Z"
        fill={hovered ? 'currentColor' : NEUTRAL_8_COLOR}
        stroke={hovered ? 'currentColor' : NEUTRAL_8_COLOR}
        stroke-width="0.00111607"
      />
    </svg>
  );
};
