import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Badge, Col, Row, theme, Typography } from 'antd';
import DOMPurify from 'dompurify';

import { useAppDispatch, useAppSelector } from '@store/store';
import { filterItemsByAdvancedFilters, reset } from '@store/slices/punch-list';
import { PunchListFloatDropdownFilters } from './PunchListFloatDropdownFilters';

interface IPunchListFilterListProps {}

export const PunchListFilterContainer = (props: IPunchListFilterListProps) => {
  const dispatch = useAppDispatch();
  const {
    token: { colorBgContainer, colorBorder, colorPrimaryTextHover, colorPrimary, colorFillQuaternary },
  } = theme.useToken();

  const { t } = useTranslation();

  let [searchURLParams, setURLSearchParams] = useSearchParams();

  const { data, advancedFilters, pageSelected } = useAppSelector(store => store.PunchList);

  // Get Advanced Filter Params and Set the First Time in Store.
  useEffect(() => {
    const category = searchURLParams?.get?.('category');
    const status = searchURLParams?.get?.('status');
    const assignedTo = searchURLParams?.get?.('assignedTo');
    const company = searchURLParams?.get?.('company');

    dispatch(
      filterItemsByAdvancedFilters({
        ...(typeof category === 'string' && { category: DOMPurify.sanitize(category) }),
        ...(typeof status === 'string' && { status: DOMPurify.sanitize(status) }),
        ...(typeof assignedTo === 'string' && { assignedTo: DOMPurify.sanitize(assignedTo) }),
        ...(typeof company === 'string' && { company: DOMPurify.sanitize(company) }),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listener of State of Filters and set in the URL
  useEffect(() => {
    setURLSearchParams({
      ...(typeof advancedFilters?.category === 'string' && { category: advancedFilters?.category }),
      ...(typeof advancedFilters?.status === 'string' && { status: advancedFilters?.status }),
      ...(typeof advancedFilters?.assignedTo === 'string' && { assignedTo: advancedFilters?.assignedTo }),
      ...(typeof advancedFilters?.company === 'string' && { company: advancedFilters?.company }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advancedFilters]);

  // On Destroy Component Reset Flow Filters
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-12">
      <Row
        className="items-center justify-center pb-8 pt-8"
        style={{ background: colorBgContainer, borderBottom: `1.2px solid ${colorBorder}` }}
      >
        <Col flex={'auto'} className="pl-9 w-min">
          <Row className="flex flex-wrap w-full justify-start items-center">
            {t('punchList.itemsFor')}
            <Typography.Title level={5} style={{ margin: 0, color: colorPrimaryTextHover, paddingLeft: 5 }}>
              {t('punchList.floorPlan')} {String(pageSelected?.order)}:
            </Typography.Title>
            <Badge
              count={data?.punchListFiltered?.length || 0}
              className="ml-10"
              style={{ backgroundColor: colorPrimary, borderColor: colorFillQuaternary }}
            />
          </Row>
        </Col>
        <Col flex="none" className="pl-20 pr-10 w-min">
          <PunchListFloatDropdownFilters />
        </Col>
      </Row>
    </div>
  );
};
