import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Form, Row, Select, Typography, theme } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useAppDispatch, useAppSelector } from '@store/store';
import { isArrayWithValues, sortCollectionByFieldString } from '@shared/util/array-util';
import { IPunchListAdvancedFilters, filterItemsByAdvancedFilters } from '@store/slices/punch-list';

interface IAdvancedFiltersContainerProps {
  setPopupVisible: (value: boolean) => void;
  initialValues: IInitalValuesForm;
}

export interface IInitalValuesForm {
  status?: string[];
}

const genericFilter = (input: string, option: any) => option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;

export const PunchListAdvancedFiltersContainer = (props: IAdvancedFiltersContainerProps) => {
  const { setPopupVisible, initialValues } = props;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    token: { colorPrimaryTextHover, colorTextQuaternary },
  } = theme.useToken();

  const { punchListCategories, punchListStatuses } = useAppSelector(store => store.PunchList);
  const { usersList } = useAppSelector(store => store.UsersExtended);
  const { entities: companies } = useAppSelector(store => store.Company);

  const onApplyFilter = () => {
    // Read Params from Form
    const category = form.getFieldValue('category');
    const status = form.getFieldValue('status');
    const assignedTo = form.getFieldValue('assignedTo');
    const company = form.getFieldValue('company');

    // Prepare / Process Params to Save in the Store
    const paramsToSet = {
      ...(isArrayWithValues(category) && { category: category.join(',') }),
      ...(isArrayWithValues(status) && { status: status.join(',') }),
      ...(isArrayWithValues(assignedTo) && { assignedTo: assignedTo.join(',') }),
      ...(isArrayWithValues(company) && { company: company.join(',') }),
    } as IPunchListAdvancedFilters;

    // Set Params in Store
    dispatch(filterItemsByAdvancedFilters(paramsToSet));
  };

  const onOk = () => {
    // Close Popover Menu
    setPopupVisible(false);
  };

  const onReset = () => {
    // Clear Form State
    form.resetFields();

    // Set Initial Values Again
    form.setFieldsValue({
      status: [],
      category: [],
      assignedTo: [],
      company: [],
    });

    // Reset Params in Store
    dispatch(filterItemsByAdvancedFilters({}));
  };

  return (
    <div>
      <div className="pt-12 pr-12 pl-12">
        <div className="flex flex-row items-center justify-between">
          <div>
            <Typography.Title level={5} style={{ margin: 0, color: colorPrimaryTextHover }}>
              {t('punchList.filtersTitle')}
            </Typography.Title>
          </div>
          <div>
            <CloseOutlined className="cursor-pointer" title="close" onClick={() => setPopupVisible?.(false)} />
          </div>
        </div>
        <Divider className="mt-12 mb-12" />

        {/* Form */}
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          preserve={false}
          onFieldsChange={onApplyFilter}
          style={{ width: '16rem' }}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="category" label={<LabelForm text={t('punchList.category')} />} className="mb-0 pb-5">
                <Select
                  mode="multiple"
                  filterOption={genericFilter}
                  options={sortCollectionByFieldString(punchListCategories, 'description').map(item => ({
                    value: String(item.id),
                    label: item.description,
                  }))}
                  allowClear={true}
                  placeholder={t('generic.filtersPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-8 mb-5" />

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="status" label={<LabelForm text={t('punchList.status')} />} className="mb-0 pb-5">
                <Select
                  mode="multiple"
                  filterOption={genericFilter}
                  options={sortCollectionByFieldString(punchListStatuses, 'status').map(item => ({
                    value: String(item.id),
                    label: item.status,
                  }))}
                  allowClear={true}
                  placeholder={t('generic.filtersPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="mt-8 mb-5" />

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="assignedTo" label={<LabelForm text={t('punchList.assignedTo')} />} className="mb-0 pb-5">
                <Select
                  mode="multiple"
                  filterOption={genericFilter}
                  options={sortCollectionByFieldString(usersList, 'fullName').map(item => ({
                    value: String(item.id),
                    label: item.fullName,
                  }))}
                  allowClear={true}
                  placeholder={t('generic.filtersPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider className="mt-8 mb-5" />

          <Row gutter={8}>
            <Col span={24}>
              <Form.Item name="company" label={<LabelForm text={t('punchList.company')} />} className="mb-0 pb-5">
                <Select
                  mode="multiple"
                  filterOption={genericFilter}
                  options={sortCollectionByFieldString(companies, 'name').map(item => ({
                    value: String(item.id),
                    label: item.name,
                  }))}
                  allowClear={true}
                  placeholder={t('generic.filtersPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Divider className="mt-10 mb-0" />

      <div className="flex flex-row justify-between pr-8 pl-8 pt-15 pb-15">
        <Button type="text" size="small" style={{ color: colorTextQuaternary }} onClick={onReset}>
          {t('generic.reset')}
        </Button>
        <Button type="primary" size="small" onClick={onOk} style={{ borderRadius: 2 }}>
          {t('generic.apply')}
        </Button>
      </div>
    </div>
  );
};

export const LabelForm = ({ text }: { text: string }) => {
  const {
    token: { colorPrimaryTextHover },
  } = theme.useToken();
  return (
    <div className="font-medium" style={{ color: colorPrimaryTextHover }}>
      {text}
    </div>
  );
};
