import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { punchListService } from '../../services/punch-list.service';
import {
  ICreatePunchListPagesWithPreviewsPagesSelectedRequest,
  IEditPunchListItemStatusRequest,
  IPunchListCheckGenerationPreviewsRequest,
  IPunchListItem,
  IPunchListItemCategory,
  IPunchListItemPageUI,
  IPunchListItemStatus,
  IPunchListItems,
  IPunchListItemsFilters,
  IPunchListPageFilters,
} from '@models/punch-list.model';
import { isArrayWithValues } from '@shared/util/array-util';
import { serializeGenericHandleError } from '@store/thunk.util';
import { isNumber } from '@shared/util/number-util';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { isStringOrNumber } from '@shared/util/validations';
import { isBoolean } from '../../shared/util/validations';

const nameOfEntity = 'punchList';
const service = punchListService;

export const getPunchListPage = createAsyncThunk(
  `${nameOfEntity}/getPunchListPage`,
  async (filters: IPunchListPageFilters, thunkAPI) => {
    return service.getPages(filters);
  },
  { serializeError: serializeGenericHandleError }
);

export const getPuchListItems = createAsyncThunk(
  `${nameOfEntity}/getPuchListItems`,
  async (filters: IPunchListItemsFilters, thunkAPI) => {
    return service.getAll(filters);
  },
  { serializeError: serializeGenericHandleError }
);

export const getPuchListItemById = createAsyncThunk(
  `${nameOfEntity}/getPuchListItemById`,
  async (id: StringORNumber, thunkAPI) => {
    return service.getById(id);
  },
  { serializeError: serializeGenericHandleError }
);

export const addPunchListItem = createAsyncThunk(
  `${nameOfEntity}/addPunchListItem`,
  async (data: { punchListItem: IPunchListItem }, thunkAPI) => {
    const { punchListItem } = data;
    return service.addPunchListItem({ punchListItem });
  },
  { serializeError: serializeGenericHandleError }
);

export const editPunchListItem = createAsyncThunk(
  `${nameOfEntity}/editPunchListItem`,
  async (data: { punchListItem: IPunchListItem }, thunkAPI) => {
    const { punchListItem } = data;
    return service.editPunchListItem({ punchListItem });
  },
  { serializeError: serializeGenericHandleError }
);

export const editPunchListItemStatus = createAsyncThunk(
  `${nameOfEntity}/editPunchListItemStatus`,
  async (data: IEditPunchListItemStatusRequest, thunkAPI) => {
    await service.editPunchListItemStatus(data);
    return service.getById(data.id);
  },
  { serializeError: serializeGenericHandleError }
);

export const editPunchListItemPoints = createAsyncThunk(
  `${nameOfEntity}/editPunchListItemPoints`,
  async (data: { punchListItem: IPunchListItem }, thunkAPI) => {
    const { punchListItem } = data;
    const punchListItemModified = await service.editPunchListItem({ punchListItem });
    if (punchListItemModified?.id) {
      thunkAPI.dispatch(selectPunchListItem(punchListItemModified));
    }
    return punchListItemModified;
  },
  { serializeError: serializeGenericHandleError }
);

export const getPunchListCategories = createAsyncThunk(
  `${nameOfEntity}/getPuchListCategories`,
  async (_, thunkAPI) => {
    return service.getCategories();
  },
  { serializeError: serializeGenericHandleError }
);

export const getPuchListStatuses = createAsyncThunk(
  `${nameOfEntity}/getPuchListStatuses`,
  async (_, thunkAPI) => {
    return service.getStatuses();
  },
  { serializeError: serializeGenericHandleError }
);

export const punchListCheckGenerationPreviews = createAsyncThunk(
  `${nameOfEntity}/getPuchpunchListCheckGenerationPreviewsListStatuses`,
  async (punchListGeneration: IPunchListCheckGenerationPreviewsRequest, thunkAPI) => {
    return service.punchListCheckGenerationPreviews(punchListGeneration);
  },
  { serializeError: serializeGenericHandleError }
);

export const createPunchListPagesWithPreviewsPagesSelected = createAsyncThunk(
  `${nameOfEntity}/createPunchListPagesWithPreviewsPagesSelected`,
  async (request: ICreatePunchListPagesWithPreviewsPagesSelectedRequest, thunkAPI) => {
    return service.punchListCreatePages(request);
  },
  { serializeError: serializeGenericHandleError }
);

export interface IPunchListAdvancedFilters {
  status?: string;
  category?: string;
  assignedTo?: string;
  company?: string;
}

interface IState {
  loading: boolean;
  addNewItemMode: boolean;
  pageSelected?: IPunchListItemPageUI;
  data: IPunchListItems;
  punchListItemHighlighted: IPunchListItem | null;
  selectPunchListItem?: IPunchListItem | null;
  punchListItemSelected?: IPunchListItem;
  punchListPages: IPunchListItemPageUI[];
  punchListCategories: IPunchListItemCategory[];
  punchListStatuses: IPunchListItemStatus[];
  allFilters: IPunchListAdvancedFilters;
  advancedFilters: IPunchListAdvancedFilters;
  advancedFiltersCount?: number;
}

const initialState: IState = {
  loading: false,
  addNewItemMode: false,
  pageSelected: undefined,
  data: {
    punchList: [] as IPunchListItem[],
    punchListFiltered: [] as IPunchListItem[],
  },
  punchListItemHighlighted: null,
  selectPunchListItem: null,
  punchListPages: [],
  punchListCategories: [],
  punchListStatuses: [],
  allFilters: {},
  advancedFilters: {},
  advancedFiltersCount: 0,
};

export const searchItemsByAllFilters = (punchList: IPunchListItem[], filters: IPunchListAdvancedFilters) => {
  let result: IPunchListItem[] = punchList;

  // Filter: Category
  if (typeof filters?.category === 'string') {
    const categoryFilter = filters?.category.split(',');
    if (isArrayWithValues(categoryFilter)) {
      result = [
        ...result.filter(item => Boolean(item?.punchlistCategory && categoryFilter?.includes(item?.punchlistCategory!.id!.toString()))),
      ];
    }
  }

  // Filter: Status
  if (typeof filters?.status === 'string') {
    const statusFilter = filters?.status.split(',');
    if (isArrayWithValues(statusFilter)) {
      result = [
        ...result.filter(item => Boolean(item?.punchlistStatus?.id && statusFilter?.includes(item?.punchlistStatus?.id!.toString()))),
      ];
    }
  }

  // Filter: Assigned To
  if (typeof filters?.assignedTo === 'string') {
    const assignedToFilter = filters?.assignedTo.split(',');
    if (isArrayWithValues(assignedToFilter)) {
      result = [...result.filter(item => Boolean(item?.assignedTo?.id && assignedToFilter?.includes(item?.assignedTo?.id!.toString())))];
    }
  }

  // Filter: Company
  if (typeof filters?.company === 'string') {
    const companyFilter = filters?.company.split(',');
    if (isArrayWithValues(companyFilter)) {
      result = [
        ...result.filter(item =>
          Boolean(item?.assignedTo?.company?.id && companyFilter?.includes(item?.assignedTo?.company?.id!.toString()))
        ),
      ];
    }
  }

  return result;
};

export const slice = createSlice({
  name: 'punchList',
  initialState,
  reducers: {
    setNewItemMode: (state, action) => {
      if (isBoolean(action.payload)) {
        state.addNewItemMode = Boolean(action.payload);
      }
      return state;
    },
    selectPage: (state, action) => {
      state.pageSelected = action.payload;
      return state;
    },
    selectPunchListItem: (state, action) => {
      state.selectPunchListItem = action.payload;
      return state;
    },
    highlightPunchListItem: (state, action) => {
      state.punchListItemHighlighted = action.payload;
      return state;
    },
    setPageByIndex: (state, action) => {
      if (isNumber(action.payload)) {
        state.pageSelected = state.punchListPages[action.payload];
        state.data.punchList = [];
      }
      return state;
    },
    setPageById: (state, action) => {
      if (isStringOrNumber(action.payload)) {
        const punchListPageIndexFounded = state.punchListPages.find(page => String(page.id) === String(action.payload));
        if (punchListPageIndexFounded) {
          state.pageSelected = punchListPageIndexFounded;
        }
      }
      return state;
    },
    filterItemsByAdvancedFilters: (state, action) => {
      state.advancedFilters = {
        ...action.payload,
      };
      state.advancedFiltersCount = Object.keys(state.advancedFilters).length;
      state.allFilters = { ...state.advancedFilters };
      if (state.data.punchList) {
        state.data.punchListFiltered = searchItemsByAllFilters(state.data.punchList, state.allFilters);
      }
      return state;
    },
    resetPunchListItemSelected: state => {
      state.punchListItemSelected = undefined;
      return state;
    },
    reset: state => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPuchListItems.fulfilled, (state, action) => {
        state.loading = false;
        state.data.punchList = isArrayWithValues(action.payload) ? action.payload : [];
        state.data.punchListFiltered = searchItemsByAllFilters(state.data.punchList, state.allFilters);
      })
      .addMatcher(isPending(getPuchListItems, addPunchListItem), state => {
        state.loading = true;
      })
      .addMatcher(isFulfilled(getPuchListItemById, editPunchListItemStatus), (state, action) => {
        state.loading = false;
        state.punchListItemSelected = action.payload;
      })
      .addMatcher(isFulfilled(addPunchListItem), (state, action) => {
        state.loading = false;
        state.data.punchList = [...state.data.punchList, action.payload];
      })
      .addMatcher(isFulfilled(getPunchListPage), (state, action) => {
        state.loading = false;
        state.punchListPages = [...action.payload];
      })
      .addMatcher(isFulfilled(getPunchListCategories), (state, action) => {
        state.loading = false;
        state.punchListCategories = [...action.payload];
      })
      .addMatcher(isFulfilled(getPuchListStatuses), (state, action) => {
        state.loading = false;
        state.punchListStatuses = [...action.payload];
      })
      .addMatcher(isFulfilled(editPunchListItem), (state, action) => {
        state.loading = false;

        const punchListUpdated = action.payload;

        // Get Index in List
        const punchListItemInListIndex = isArrayWithValues(state.data.punchList)
          ? state.data.punchList.findIndex(punchListItem => String(punchListItem.id) === String(punchListUpdated?.id))
          : -1;

        if (isNumber(punchListItemInListIndex)) {
          state.data.punchList[punchListItemInListIndex] = { ...state.data.punchList[punchListItemInListIndex], ...punchListUpdated };
        }

        // Get Index in the filtered list
        const punchListItemInFilteredListIndex = isArrayWithValues(state.data.punchListFiltered)
          ? state.data.punchListFiltered.findIndex(punchListItem => String(punchListItem.id) === String(punchListUpdated?.id))
          : -1;

        if (isNumber(punchListItemInFilteredListIndex) && punchListItemInFilteredListIndex !== -1) {
          state.data.punchListFiltered[punchListItemInFilteredListIndex] = {
            ...state.data.punchListFiltered[punchListItemInFilteredListIndex],
            ...punchListUpdated,
          };
        }

        return state;
      });
  },
});

export const {
  selectPage,
  setPageByIndex,
  setPageById,
  selectPunchListItem,
  highlightPunchListItem,
  reset,
  resetPunchListItemSelected,
  setNewItemMode,
  filterItemsByAdvancedFilters,
} = slice.actions;

// Reducer
export default slice.reducer;
