import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, Popover, theme } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

import { PunchListAdvancedFiltersContainer, IInitalValuesForm } from './PunchListAdvancedFiltersContainer';
import { useAppSelector } from '@store/store';

export const PunchListFloatDropdownFilters = () => {
  const {
    token: { colorBorder },
  } = theme.useToken();

  const { t } = useTranslation();

  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const { advancedFiltersCount, advancedFilters } = useAppSelector(store => store.PunchList);

  const [initialValues, setInitialValues] = useState<IInitalValuesForm>({});

  useEffect(() => {
    if (advancedFilters) {
      setInitialValues({
        ...(advancedFilters.category && { category: advancedFilters.category.split(',') }),
        ...(advancedFilters.status && { status: advancedFilters.status.split(',') }),
        ...(advancedFilters.assignedTo && { assignedTo: advancedFilters.assignedTo.split(',') }),
        ...(advancedFilters.company && { company: advancedFilters.company.split(',') }),
      });
    }
  }, [advancedFilters]);

  return (
    <Popover
      content={<PunchListAdvancedFiltersContainer setPopupVisible={setPopupVisible} initialValues={initialValues} />}
      placement="bottomRight"
      trigger={['click']}
      arrow={false}
      zIndex={20}
      open={popupVisible}
      onOpenChange={visible => {
        setPopupVisible(visible);
      }}
      destroyTooltipOnHide={true}
      overlayInnerStyle={{ border: `1px solid ${colorBorder}`, padding: 0, marginTop: 8 }}
    >
      <div onClick={() => setPopupVisible(!popupVisible)}>
        <Badge count={advancedFiltersCount} offset={[-1, 3]}>
          <Button type="primary" {...(typeof advancedFiltersCount !== 'undefined' && advancedFiltersCount > 0 && { ghost: true })}>
            <FilterOutlined />
            {t('generic.filter')}
          </Button>
        </Badge>
      </div>
    </Popover>
  );
};
