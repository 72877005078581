import { theme } from 'antd';

import { HeaderPunchListSidePanel } from './HeaderPunchListSidePanel/HeaderPunchListSidePanel';
import { PunchListContainer } from './PunchListContainer/PunchListContainer';
import { ManageBarContainer } from './ManageBar/ManageBarContainer';
import { CollapsibleButton } from './ManageBar/CollapsibleButton';
import { useLayoutContex } from '@providers/LayoutProvider';
import { PunchListFilterContainer } from './FilterContainer/PunchListFilterContainer';

export const SidePanel = () => {
  const {
    token: { colorBorder },
  } = theme.useToken();

  const { collapsePunchList } = useLayoutContex();

  return (
    <div className="relative h-full w-full overflow-hidden" style={{ borderRight: `1.2px solid ${colorBorder}` }}>
      <div style={{ display: collapsePunchList ? 'none' : 'block' }}>
        <div>
          <div className="absolute overflow-y-auto h-full w-full">
            <div className="overflow-auto h-full">
              <HeaderPunchListSidePanel />
              <PunchListFilterContainer />
              <PunchListContainer />
            </div>
          </div>

          <div className="absolute w-full" style={{ bottom: 0, left: 0, borderTop: `1.2px solid ${colorBorder}` }}>
            <ManageBarContainer />
          </div>
        </div>
      </div>

      <div className="absolute inline w-min" style={{ bottom: 10, left: 25 }}>
        <CollapsibleButton />
      </div>
    </div>
  );
};
